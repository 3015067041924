body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 12px;
}

.ant-layout-content {
  background: #F9F9F9;
}

.dashboard .ant-layout-content {
  padding-bottom: 24px;
}

.ant-layout-footer {
  background: #F9F9F9;
}

.loading {
  width: 100%;
}

.ant-btn {
  font-size: 1rem;
  font-weight: bold;
  height: auto;
  padding: 5px 20px;
}

.ant-btn-primary {
  background: #1E417B;
  border-color: #1E417B;
  color: #ffffff;
}

.ant-menu.ant-menu-sub\-vertical {
  background-color: #FFFFFF;
}