body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 12px;
}

.ant-layout-content {
  background: #F9F9F9;
}

.dashboard .ant-layout-content {
  padding-bottom: 24px;
}

.ant-layout-footer {
  background: #F9F9F9;
}

.loading {
  width: 100%;
}

.ant-btn {
  font-size: 1rem;
  font-weight: bold;
  height: auto;
  padding: 5px 20px;
}

.ant-btn-primary {
  background: #1E417B;
  border-color: #1E417B;
  color: #ffffff;
}

.ant-menu.ant-menu-sub-vertical {
  background-color: #FFFFFF;
}
.logo {
    position: absolute;
    width: 120px;
    height: 31px;
    top: 0;
    left: 50%;
    margin-left: -60px;
}

.logo img {
    width: 100%;
}

.actions-container {
    text-align: center;
}

.actions-container .ant-btn {
    /* height: 55px; */
    padding: 7px 30px;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0 10px;
}

.ant-btn-group.no-border .ant-btn {
    border: none;
    box-shadow: none;
    font-size: .8rem;
}

ul.menu-horizontal.ant-menu-horizontal {
    display: flex;
    justify-content: center;
    background: #1E417B;
}

ul.menu-horizontal.ant-menu-horizontal li a {
    color: #f9f9f9;
    letter-spacing: 1.3px;
}

ul.menu-horizontal.ant-menu-horizontal li.ant-menu-item-selected a {
    color: #E74B4B;
}

.menu-horizontal.ant-menu-horizontal>.ant-menu-item.ant-menu-item-selected {
    border-bottom: 2px solid #E74B4B;
}

ul.second-menu.ant-menu-horizontal {
    display: flex;
    justify-content: center;
}

ul.second-menu.ant-menu-horizontal li a {
    letter-spacing: 1.3px;
}

.text-center {
    text-align: center;
}

.hide {
    display: none;
}

.auth-footer {
    color: #1E417B;
    font-weight: bolder;
    letter-spacing: 1.5px;
}

.auth-footer .footer-socials a {
    margin: 0 10px;
}

h1.sidebar-header {
    color: #1E417B;
    margin: 10px 0 10px 20px;
}

.text-right {
    text-align: right;
}



.property-selection {
    font-size: 2rem;
    margin: 20px;
}

.property-selection a {
    color: #183E7C;
    word-wrap: normal;
}

.property-selection .ant-select-selection.ant-select-selection--single {
    background-color: transparent;
    word-wrap: normal;
    border: 0;
    max-width: 100%;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    height: auto;
}

.property-selection .ant-select-selection-selected-value {
    text-overflow: initial;
    white-space: normal;
}

.property-selection__dropdown .ant-select-dropdown-menu-item-group-title {
    font-size: 1.1rem;
    color: #AAB0B4;
}

.property-selection__dropdown .ant-select-dropdown-menu-item a {
    font-size: .7rem;
    color: #333333;
}

.property-selection__dropdown .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected a {
    color: #1E407B;
}

.property-selection__dropdown .ant-select-dropdown-menu-item-group {
    margin-bottom: 15px;
}

.selected-brand {
    color: #AAB0B4;
    margin: 0 20px;
    font-size: 1rem;
}

.add-btn {
    margin-left: 8px;
    background: #1E417B;
    border-color: #1E417B;
    color: #ffffff;
}

.layout-sider {
    background: #EDF4F9;
}

.layout-sider .ant-menu.ant-menu-root.ant-menu-inline,
.layout-sider .ant-menu.ant-menu-sub.ant-menu-inline {
    background-color: transparent;
}

@media (min-width: 768px) and (min-width: 1023px) {
    ul.menu-horizontal.ant-menu-horizontal li {
        padding: 0 10px;
    }
}

@media (max-width: 767px) {

    ul.menu-horizontal.ant-menu-horizontal,
    ul.second-menu.ant-menu-horizontal {
        display: block;
        text-align: center;
    }

    ul.menu-horizontal.ant-menu-horizontal li,
    ul.second-menu.ant-menu-horizontal li {
        width: 100%;
    }

    .text-right {
        text-align: left;
    }
}

.ql-editor {
    min-height: 120px;
}

.page-title-container {
    margin: 20px 0;
    color: #333333;
    font-size: 1rem;
}

.page-title-container .parent {
    color: #333333;
}

.page-title-container .divider {
    color: #aaaaaa;
}

.page-title-container .current {
    color: #1e417b;
    font-size: 1.5rem;
}
